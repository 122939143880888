<template>
    <v-container>
        <v-row class="my-4 mx-0 pa-0" align="center">
            <h1 class="text-h5">
                <router-link color="primary" :to="`/account`" style="text-decoration: none">
                    アカウント
                </router-link>
            </h1>
            <v-icon left>mdi-chevron-right</v-icon>
            <div class="text-h5 mr-2">
                <h1 class="text-h5" color="#000000DE">
                    パスワード変更
                </h1>
            </div>
        </v-row>
        <div class="justify-left" style="width:100%;">
            <v-card width="100%" class="mt-8 px-4">
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                    <v-container :loading="loading">
                        <v-text-field v-model="passwordOld" :error-messages="passwordOldErrors" type="password"
                            maxlength="50" label="元のパスワード" @input="$v.passwordOld.$touch()"
                            @blur="$v.passwordOld.$touch()"></v-text-field>
                        <v-text-field v-model="password1" :error-messages="password1Errors" type="password" maxlength="32"
                            label="新しいパスワード" @input="$v.password1.$touch()" @blur="$v.password1.$touch()"></v-text-field>

                        <v-text-field v-model="password2" :error-messages="password2Errors" type="password" maxlength="32"
                            label="新しいパスワード（確認用）" @input="$v.password2.$touch()"
                            @blur="$v.password2.$touch()"></v-text-field>

                        <p class="red--text body-2 ma-0 pa-0" v-for="error in passwordSecondErrors" :key="error">
                            ・{{ error }}
                        </p>
                        <v-row class="justify-center">
                            <v-btn outlined color="narekomu" class="white--text my-8" width="100px" :loading="loading"
                                :disabled="!valid || !checkPassword || loading" @click="changePassword">
                                送信
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
            <DialogStatus :item="sItem" v-on:closeEmit="closeStatus" />
        </div>
    </v-container>
</template>

<script>
import axios from "axios";
const DialogStatus = () => import("@/components/DialogStatus.vue");
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
    name: "ChangePassword",
    components: { DialogStatus },
    mixins: [validationMixin],
    validations: {
        password1: { required, minLength: minLength(8), maxLength: maxLength(32) },
        password2: { required, minLength: minLength(8), maxLength: maxLength(32) },
        passwordOld: { required, minLength: minLength(8), maxLength: maxLength(32) },
    },
    data: () => ({
        password1: "",
        password2: "",
        passwordOld: "",
        valid: true,
        loading: false,
        sItem: {
            loadText: "通信中...",
            textError: "予期せぬエラーが起きました",
            compText: `パスワードの変更が完了しました。`,
            type: "",
            reloadError: false,
            toTop: false,
            close: true,
        },
    }),

    computed: {
        password1Errors() {
            let errors = [];
            if (!this.$v.password1.$dirty) return errors;
            if (!this.$v.password1.required) errors.push("必須項目です");
            else if (!this.$v.password1.minLength) errors.push("パスワードは8文字以上で入力してください");
            else if (!this.$v.password1.maxLength) errors.push("パスワードは32文字以下で入力してください");
            return errors;
        },

        password2Errors() {
            let errors = [];
            if (!this.$v.password2.$dirty) return errors;
            if (!this.$v.password2.required) errors.push("必須項目です");
            if (!this.$v.password2.minLength) errors.push("パスワードは8文字以上で入力してください");
            if (!this.$v.password2.maxLength) errors.push("パスワードは32文字以下で入力してください");
            return errors;
        },

        passwordOldErrors() {
            let errors = [];
            if (!this.$v.passwordOld.$dirty) return errors;
            if (!this.$v.passwordOld.required) errors.push("必須項目です");
            if (!this.$v.passwordOld.minLength) errors.push("パスワードは8文字以上で入力してください");
            if (!this.$v.passwordOld.maxLength) errors.push("パスワードは32文字以下で入力してください");
            return errors;
        },

        passwordSecondErrors() {
            let errors = [];
            if (!this.$v.password1.$dirty || !this.$v.password2.$dirty || !this.$v.passwordOld.$dirty)
                return errors;
            if (this.password1.length < 8 || this.password2.length < 8)
                errors.push("パスワードは8文字以上で入力してください");
            else if (this.password1 != this.password2) errors.push("確認用パスワードが違います。");
            if (this.passwordOld.length == 0) errors.push("元のパスワードを入力してください");
            else if (this.password1 == this.passwordOld)
                errors.push("新しいパスワードに元もパスワードと同じものが入力されています。");
            return errors;
        },

        checkPassword() {
            if (
                this.passwordSecondErrors.length == 0 &&
                this.password1Errors.length == 0 &&
                this.password2Errors.length == 0 &&
                this.passwordOldErrors.length == 0
            )
                return true;
            else return false;
        },
    },

    methods: {
        async changePassword() {
            if (process.env.NODE_ENV === "development") {
                console.log(this.$refs.form.validate());
            }
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.sItem.type = "load";

                try {
                    const response = await axios.put(
                        `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/account`,
                        {
                            NewPassword: this.password1,
                            OldPassword: this.passwordOld,
                        },
                        {
                            headers: {
                                "X-Api-Key": process.env.VUE_APP_KEY,
                                SessionId: this.$store.state.auth.login.sessionId,
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    if (response.data.results === "complete") {
                        this.sItem.type = "comp";
                        this.loading = false;
                        this.reset();
                    } else {
                        this.loading = false;
                        this.sItem.type = "error";
                    }
                } catch (reason) {
                    console.log(reason.response);
                    this.loading = false;
                    this.sItem.type = "error";
                    this.sItem.errorText =
                        reason.response.status === 403
                            ? "パスワードが正しくありません"
                            : reason.response.data;
                }
            }
        },

        closeStatus() {
            this.sItem.type = "";
        },

        reset() {
            this.$v.$reset();
            this.password1 = "";
            this.password2 = "";
            this.passwordOld = "";
        },
    },
};
</script>
